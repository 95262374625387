




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Dashboard extends Vue {
  private text = {
    name: "Dashboard"
  };

  beforeCreate() {
    this.$store.commit("showTopBar");
    this.$store.commit("showNavBar");
  }
  beforeUpdate() {
    this.$store.commit("showNavBar");
    this.$store.commit("showTopBar");
  }
}
